.dataportal-body {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100%;
  
  .sidebar {
    width: 20%;
    padding-right: 15px;
  }
  
  .small-open {
    width: 60%;
    padding-right: 15px;
  }
  
  .small-closed {
    flex-basis: 0%;
    display: none;
  }
  
  .map {
    width: 100%;
  }
}