@import "../../styles/variables";

.sidebar {
	> * {
		margin-bottom: 0.5rem;
	}

	.sidebar-button {
		color: white;
		width: 100%;
		background-color: $darkblue;
		padding-top: 1.6rem;
		padding-bottom: 1.1rem;
		text-align: center;
	}

	.sidebar-button:hover {
		filter: brightness(1.5);
	}

	.greenblue-thing {
		color: $dugnadgrey !important;
	}
}
