@import "bootstrap";
@import "./styles/fonts.scss";
@import "./styles/_variables.scss";

body {
	font-family: Calibre !important;
}

.header-blue {
	color: $darkblue;
	text-transform: none !important;
}

@include media-breakpoint-up(md) {
	.text-md-justify {
		text-align: justify !important;
	}
}

