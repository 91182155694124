@import "../../styles/variables";

// WARNING: Crappy CSS structure. Feel free to improve

.register-finds {
	h1, h2, h3, h4, h5, h6 {
		font-weight: bold;
		text-transform: uppercase;
	}

	.to-systems {
		border-bottom: solid $dugnadgrey 1px;
		padding-bottom: 2rem;
		margin-bottom: 2rem;

		a {
			color: white;
		}

		h2 {
			padding-top: 1.5rem;
			padding-bottom: 1.0rem;
		}

		.havfunn h2 {
			background-color: $greenblue;
		}
		.havfunn h2:hover {
			filter: brightness(1.1);
		}

		.tidsserier h2 {
			background-color: $darkblue;
		}
		.tidsserier h2:hover {
			filter: brightness(1.4);
		}

		.excursion h2 {
			background-color: #00b0f0;
		}
		.excursion h2:hover {
			filter: brightness(1.1);
		}

		.register-user h2 {
			background-color: $someblue;
		}
		.register-user h2:hover {
			filter: brightness(1.2);
		}
	}
}
