@import "../../styles/variables";

// WARNING: Crappy CSS structure. Feel free to improve

.frontpage {
	h1, h2, h3, h4, h5, h6 {
		font-weight: bold;
		text-transform:	uppercase;
	}

	h1 {
		font-size: 4rem;
	}

	> div:first-child {
		background-image: url("../../assets/img/top-background.jpg");
		background-size: auto auto;
		background-repeat: no-repeat;
		background-position: top;
	}

	header {
		margin: 8rem 0 6.2rem 0;
		color: white;
		font-weight: bolder;
	}

	.to-system {
		a {
			max-width: 500px;
			color: white;

			img {
				width: 100%;
			}
		}

		.register-finds {
			background-color: $greenblue;
		}

		.see-registrations {
			background-color: $darkblue;
		}

		.spacer {
			width: 200px;
		}

		a:hover {
			filter: brightness(1.2);
			text-decoration: none;
		}

		p {
			color: $dugnadgrey;
			text-decoration: underline;
			padding: 0 3rem;
		}
	}

	.container {
		h1, h2, h3, h4, h5, h6 {
			color: $darkblue;
		}
	}

	.explore {
		position: relative;

		img {
			width: 100%;
		}

		a div {
			display: flex;
			justify-content: center;
			align-items: center;

			text-align: center;
			margin: auto;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 15px;
			right: 15px;

			h2 {
				width: 66%;
				padding: 1rem 0.3rem 0.5rem 0.3rem;
				background-color: $darkblue;
				color: white;
			}
		}

		a:hover h2 {
			background-color: lighten($darkblue, 20%);
		}
	}

	.citizen-scientist {
		border-top: solid $dugnadgrey 1px;
		border-bottom: solid $dugnadgrey 1px;

		h1 {
			font-size: 3rem;
		}
	}
	.citizen-scientist a:hover {
		filter: brightness(1.2);
	}

	.read-more {
		background-color: $darkblue;
		color: white;
		text-transform: uppercase;
		font-weight: 1000;
		padding: 0.9rem 2rem 0.5rem 2rem;
		margin-top: 1rem;
		border: none;
	}
}
