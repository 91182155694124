@import "../../styles/variables";

.scientist-corner-post {
	h1, h2, h3, h4, h5, h6 {
		font-weight: bold;
	}

	.author::before {
		content: "— "
	}

	time {
		color: gray;
	}
}
