@import "../../styles/variables";

.citizen-scientist {
	h1, h2, h3, h4, h5, h6 {
		font-weight: bold;
		text-transform: uppercase;
	}

	.greenblue-thing {
		background-color: $greenblue-thing;
		color: $greenblue-thing-text;
		color: white;

		padding: 1.5rem;
	}
}
