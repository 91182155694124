.filter-button {
    position: absolute;
    top: 10px;
    left: 10px;
    z-Index: 1000;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.ol-zoom {
	top: 10px;
	left: auto;
	right: 10px;
}