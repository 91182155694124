@import "../../styles/variables";

.scientist-corner-index {
	h1, h2, h3, h4, h5, h6 {
		color: $darkblue;
		font-weight: bold;
	}

	time {
		color: gray;
	}

	li {
		padding-bottom: 2rem;
		margin-bottom: 2rem;
		border-bottom: solid $dugnadgrey 1px;
	}
}
