.new-map-link-card {
    background-color: #f8f9fa;
    text-align: center;
    z-index: 1000;
    font-size:20px;
    font-weight: bold;
    word-wrap: break-word;
    background-color:#c0e09d;
    position: relative;
  }

  .minimize-button {
    display: inline;
    cursor: pointer;
  }
