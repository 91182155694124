@import "../../styles/variables";

.life-in-the-ocean {
	h1, h2, h3, h4, h5, h6 {
		font-weight: bold;
		text-transform: uppercase;
	}

	.registration-type-header {
		h2 {
			display: inline;
			margin-right: 0.7rem;
		}

		.btn.btn-link {
			color: $darkblue;
		}
	}

	.greenblue-thing {
		background-color: $greenblue-thing;
		color: $greenblue-thing-text;

		padding: 1rem;
		margin-bottom: 1rem;
	}

	.bottom-border {
		padding-bottom: 0.8rem;
		margin-bottom: 1.5rem;
		border-bottom: solid $dugnadgrey 1px;
	}

	ul img {
		max-width: 100%;
	}
}
