header.header {
	background-image: url("../../assets/img/header-background.jpg");
	background-position: top;
	padding-top: 3.3rem;
	padding-bottom: 2rem;
	margin-bottom: 4rem;

	h1 {
		color: white;
		font-weight: bold;
		text-transform:	uppercase;
	}
}
