@import "../../styles/variables";

.scientist-corner {
	li {
		border-top: solid #a7a5a6 1px;
		font-size: 0.9rem;

		a {
			color: $dugnadgrey;
		}
	}
}
