.statistics {
  padding: 1rem;
  background-color: #d3d2d2;

  >ul>li {
    background-color: white;
    border-radius: 1rem;
    padding: 0.5rem 1rem;

    > :first-child {
      display: block;
      font-weight: bold;
      font-size: 1.5rem;
    }
  }
}
