@import "../../styles/variables";

nav {
	background-color: white;

	.navbar-brand img {
		max-height: 3rem;
	}

	.navbar-brand, button, .nav-link, .dropdown-item {
		color: $darkblue !important;
		font-size: 1.2rem;
		font-weight: bold;
		text-transform: uppercase;
	}

	.dropdown-hack {
		color: white;
		z-index: 1000;
		background-color: $darkblue;
		position: absolute;
		right: 0;
		top: 74px;
		text-transform: uppercase;
		padding: 2rem;

		a, .btn-link {
			padding: 0;
			color: white !important;
		}
	}
}
