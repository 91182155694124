@import "../../styles/variables";
@import "bootstrap";

// WARNING: Crappy CSS structure. Feel free to improve

footer {
	background-color: $darkblue;

	@include media-breakpoint-up(md) {
		background-image: url("../../assets/img/footer-background.png");
		background-repeat: no-repeat;
		background-size: auto 70%;
		background-position: 100% 30%;
	}

	color: white;

	img.logo {
		max-width: 100%;
	}

	h3 {
		font-weight: normal !important;
	}

	a {
		color: white;
	}

	a:hover {
		text-decoration: underline;
		color: white;
	}

	.background {
		position: absolute;
		left: 0;
		top: 10px;
	}
}
